<template>
  <div class="content-box">
    <div v-loading="loading" class="mx-auto login p-20 box-shadow" style="padding-bottom:40px">
      <div class="header text-center mb-25">
        <h2 class="mb-0">{{ $t("login.title") }}</h2>
        <p class="mt-10 mb-0 color-6 font-size-14">
          {{ $t("login.dont_have_an_account") }}
          <el-link
            class="color-primary is-link"
            @click="$nav.replace('register')"
            >{{ $t("login.to_register") }}</el-link
          >
        </p>
      </div>
      <el-divider />
      <el-form ref="ruleForm" :model="formData" :rules="rules">
        <el-form-item prop="email">
          <el-input
            v-model="formData.email"
            prefix-icon="el-icon-user"
            :placeholder="$t('login.emailPhone')"
          />
          <!-- @input="emailInput()" -->
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="formData.password"
            prefix-icon="el-icon-lock"
            :placeholder="$t('login.password') + $t('login.min6')"
            show-password
          />
        </el-form-item>
        <!-- <el-form-item prop="captcha">
          <el-input
            v-model="formData.captcha"
            prefix-icon="el-icon-s-check"
            :placeholder="$t('login.captcha')"
          >
            <template slot="append">
              <img
                v-if="captchaSrc"
                class="captcha"
                :src="captchaSrc"
                :alt="$t('login.captcha')"
                @click="showCaptcha()"
              />
              <el-button
                v-else
                plain
                type="success"
                native-type="button"
                @click="showCaptcha()"
                >{{ $t("login.click_me_show") }}</el-button
              >
            </template>
          </el-input>
        </el-form-item> -->
      </el-form>
      <el-divider />
      <div class="mt-30 mx-20">
        <el-button class="w-100" type="primary" @click="submitForm">{{
          $t("login.sign_in_now")
        }}</el-button>
      </div>
      <!-- 忘记密码入口，先注释，因为没有邮箱验证 -->
      <!-- <div class="mt-30 mb-20 text-center font-size-14">
        <el-link
          class="color-primary is-link"
          @click="$nav.replace('forget')"
          >{{ $t("login.forget_password") }}</el-link
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import { validEmail } from "@/services/validate";
export default {
  data() {
    return {
      loading: false,
      captchaSrc: null,
      formData: {
        email: this.$store.state.userEmail,
        password: "",
        // captcha: "",
      },
      rules: {
        // email: [
        //     { required: true, type: 'email', message: 'Please enter Email address', trigger: 'blur' }
        // ],
        // password: [
        //     { required: true, type: 'string', min: 6, message: 'More than 6 characters', trigger: 'blur' }
        // ],
        // captcha: [
        //     { required: true, type: 'string', min: 4, message: 'More than 4 characters', trigger: 'blur' }
        // ]

        email: [
          {
            required: true,
            // type: "email",
            message: this.$t("login.rules1"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            min: 6,
            message: this.$t("login.rules2"),
            trigger: "blur",
          },
        ],
        // captcha: [
        //   {
        //     required: true,
        //     min: 4,
        //     message: this.$t("login.captcha_plc"),
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    showCaptcha: function () {
      if (validEmail(this.formData.email)) {
        this.captchaSrc =
          "/api/login/captcha/id/" +
          this.formData.email +
          "?time=" +
          new Date().getTime();
      } else {
        this.$message({
          message: "Please enter Email address",
          type: "error",
        });
      }
    },
    emailInput: function () {
      this.formData.captcha = "";
      this.captchaSrc = null;
    },
    submitForm: async function () {
      await this.$refs.ruleForm.validate();
      this.loading = true;
      try {
        // const { data } = await this.$request.post("login", this.formData);
        // v1/login/login
        // const { data } = await this.$request.post("v1/login/login", this.formData);
        
        let $formData = this.formData

        $formData.login_channel = 'pc'
        
        const { data } = await this.$request.post("v1/login/login", $formData);
        this.$store.commit("userToken", data.token);
        this.$store.commit("userEmail", data.email);
        this.$store.commit("userPhone", data.userPhone);
        this.$store.commit("userId", data.id);
        this.$nav.back();
      } catch (error) {
        this.loading = false;
        // 去掉了图形验证码先注释
        // this.formData.captcha = "";
        // this.captchaSrc = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 400px;
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: white;
  color: #333;
  border-radius: 8px;
}
</style>